<template>
  <transition name="fade">
    <ul class="list-unstyled">
      <li v-for="item in list" :key="item.index">
        <span class="text-danger">{{ capitilize(item) }}</span>
      </li>
    </ul>
  </transition>
</template>

<script>
export default {
  name: "ErrorsList",
  props: {
    list: {
      type: Array,
      require: true,
    },
  },
  methods: {
    capitilize(item) {
      if (typeof item !== "undefined") {
        return item.charAt(0).toUpperCase() + item.slice(1);
      }
      return "";
    },
  },
};
</script>
