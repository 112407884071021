import { render, staticRenderFns } from "./MaxRows.vue?vue&type=template&id=2b39c2ba&scoped=true&"
import script from "./MaxRows.vue?vue&type=script&lang=js&"
export * from "./MaxRows.vue?vue&type=script&lang=js&"
import style0 from "./MaxRows.vue?vue&type=style&index=0&id=2b39c2ba&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b39c2ba",
  null
  
)

export default component.exports