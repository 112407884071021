<template>
  <LoadingPage v-if="load" />
  <div v-else>
    <div>
      <back-button />
      <div class="d-flex justify-content-between mt-3">
        <h5 class="font-weight-light text-uppercase">
          Amazon Import Files ({{ this.totalRows }})
        </h5>
        <MaxRows class="m-1" :perPage="perPage" @emitValue="perPage = $event" />
      </div>

      <table class="table table-striped shadow-sm rounded mt-2">
        <thead>
          <tr class="table-head">
            <th class="text-secondary text-center">Import Id</th>
            <th class="text-secondary text-center">Date of import</th>
            <th class="text-secondary text-center">File</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(file, index) in files" :key="index">
            <td class="text-secondary text-center">{{ file.id }}</td>
            <td class="text-secondary text-center">
              {{ formatDate(file.created_at) }}
            </td>
            <td class="text-secondary text-center">
              <button
                v-on:click="generateFile(file.id)"
                type="button"
                class="btn btn-link"
              >
                <i class="fas fa-download"></i> Click here to download CSV
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <b-pagination
      class="pb-3"
      aria-controls="my-table"
      align="center"
      size="sm"
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      first-text="<<"
      prev-text="Prev"
      next-text="Next"
      last-text=">>"
    />
  </div>
</template>

<script>
import { BPagination } from "bootstrap-vue";
import OrderService from "../../services/OrderService";
import LoadingPage from "@/components/loadings/LoadingPage.vue";
import moment from "moment";
import MaxRows from "@/components/tables/MaxRows.vue";
import BackButton from "../../components/generic/BackButton";

const orderService = new OrderService();

export default {
  name: "AmazonFiles",
  components: {
    BPagination,
    LoadingPage,
    MaxRows,
    BackButton,
  },
  data: () => {
    return {
      load: true,
      files: [],
      loaded: false,
      perPage: 0,
      currentPage: 1,
      totalRows: 0,
    };
  },
  beforeMount() {
    this.getData(this.currentPage);
    this.loaded = true;
    this.load = false;
  },
  methods: {
    generateFile(id) {
      orderService.generateLink(id).then((response) => {
        let csv = response.data.data.link;
        let filename = `Amazon_import_${id}.csv`;
        let anchor = document.createElement("a");
        anchor.href = csv;
        anchor.target = "_blank";
        anchor.download = filename;
        anchor.click();
      });
    },
    getData(page) {
      orderService.getAmazonImports(this.perPage, page).then((response) => {
        this.files = response.data.data.files;
        this.totalRows = response.data.data.all_count;
      });
    },
    formatDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
  },
  watch: {
    currentPage(newValue) {
      this.getData(newValue);
    },
  },
};
</script>

<style scoped>
.table.table-striped > thead > .table-head {
  top: -25px !important;
}
</style>
