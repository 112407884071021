<template>
  <LoadingPage v-if="loaded" />
  <div v-else>
    <div>
      <back-button />
      <div class="d-flex justify-content-between mt-3">
        <h5 class="font-weight-light text-uppercase">Orders to Fix</h5>
        <button
          class="btn btn-primary"
          @click="saveOrders()"
          title="Unselect All Columns"
        >
          Save Orders
        </button>
      </div>
      <NbTable
        :shortThead="true"
        class="pb-1"
        tableOf="Amazon Import"
        :total="orders.length"
        :items="orders"
        :fields="fields"
        :clickable="true"
        @clicked="clicked"
        :key="unique"
      >
        <template #cell(status)="data">
          <div>
            <span
              v-if="Object.keys(errors[data.item.index]).length === 0"
              class="badge badge-success"
              >Ok to save</span
            >
            <span v-else class="badge badge-danger">Incompleted </span>
          </div>
        </template>

        <template #cell(order_number)="data">
          <div
            v-if="
              errors[data.item.index] && errors[data.item.index].order_number
            "
            class="text-danger"
          >
            <i class="fas fa-exclamation-triangle"></i>
          </div>
        </template>

        <template #cell(contract_id)="data">
          <div
            v-if="
              errors[data.item.index] && errors[data.item.index].contract_id
            "
            class="text-danger"
          >
            <i class="fas fa-exclamation-triangle"></i>
          </div>
        </template>

        <template #cell(incoterm)="data">
          <div
            v-if="errors[data.item.index] && errors[data.item.index].incoterm"
            class="text-danger"
          >
            <i class="fas fa-exclamation-triangle"></i>
          </div>
        </template>

        <template #cell(customer_full_name)="data">
          <div
            v-if="
              errors[data.item.index] &&
              errors[data.item.index].customer_full_name
            "
            class="text-danger"
          >
            <i class="fas fa-exclamation-triangle"></i>
          </div>
        </template>

        <template #cell(customer_document_number)="data">
          <div
            v-if="
              errors[data.item.index] &&
              errors[data.item.index].customer_document_number
            "
            class="text-danger"
          >
            <i class="fas fa-exclamation-triangle"></i>
          </div>
        </template>

        <template #cell(customer_postal_code)="data">
          <div
            v-if="
              errors[data.item.index] &&
              errors[data.item.index].customer_postal_code
            "
            class="text-danger"
          >
            <i class="fas fa-exclamation-triangle"></i>
          </div>
        </template>

        <template #cell(customer_address)="data">
          <div
            v-if="
              errors[data.item.index] &&
              errors[data.item.index].customer_address
            "
            class="text-danger"
          >
            <i class="fas fa-exclamation-triangle"></i>
          </div>
        </template>

        <template #cell(customer_address_number)="data">
          <div
            v-if="
              errors[data.item.index] &&
              errors[data.item.index].customer_address_number
            "
            class="text-danger"
          >
            <i class="fas fa-exclamation-triangle"></i>
          </div>
        </template>

        <template #cell(customer_address_complement)="data">
          <div
            v-if="
              errors[data.item.index] &&
              errors[data.item.index].customer_address_complement
            "
            class="text-danger"
          >
            <i class="fas fa-exclamation-triangle"></i>
          </div>
        </template>

        <template #cell(customer_address_reference)="data">
          <div
            v-if="
              errors[data.item.index] &&
              errors[data.item.index].customer_address_reference
            "
            class="text-danger"
          >
            <i class="fas fa-exclamation-triangle"></i>
          </div>
        </template>

        <template #cell(customer_city)="data">
          <div
            v-if="
              errors[data.item.index] && errors[data.item.index].customer_city
            "
            class="text-danger"
          >
            <i class="fas fa-exclamation-triangle"></i>
          </div>
        </template>

        <template #cell(customer_state)="data">
          <div
            v-if="
              errors[data.item.index] && errors[data.item.index].customer_state
            "
            class="text-danger"
          >
            <i class="fas fa-exclamation-triangle"></i>
          </div>
        </template>

        <template #cell(customer_country)="data">
          <div
            v-if="
              errors[data.item.index] &&
              errors[data.item.index].customer_country
            "
            class="text-danger"
          >
            <i class="fas fa-exclamation-triangle"></i>
          </div>
        </template>

        <template #cell(customer_phone)="data">
          <div
            v-if="
              errors[data.item.index] && errors[data.item.index].customer_phone
            "
            class="text-danger"
          >
            <i class="fas fa-exclamation-triangle"></i>
          </div>
        </template>

        <template #cell(customer_email)="data">
          <div
            v-if="
              errors[data.item.index] && errors[data.item.index].customer_email
            "
            class="text-danger"
          >
            <i class="fas fa-exclamation-triangle"></i>
          </div>
        </template>

        <template #cell(freight_value)="data">
          <div
            v-if="
              errors[data.item.index] && errors[data.item.index].freight_value
            "
            class="text-danger"
          >
            <i class="fas fa-exclamation-triangle"></i>
          </div>
        </template>

        <template #cell(currency)="data">
          <div
            v-if="errors[data.item.index] && errors[data.item.index].currency"
            class="text-danger"
          >
            <i class="fas fa-exclamation-triangle"></i>
          </div>
        </template>
      </NbTable>
    </div>
    <b-modal
      id="modalAmazonImport"
      size="lg"
      title="Edit Order Details"
      ok-title="Check Orders"
      @ok="checkOrder"
      scrollable
    >
      <b-container>
        <b-row cols="2">
          <div v-for="(value, key) in modalOrder" :key="key" class="bg-info">
            <b-col
              v-if="
                editableFields
                  .map((inner) => Object.values(inner)[0])
                  .includes(key) && key !== 'volumes'
              "
              :key="key"
            >
              <div class="form-group mb-2 pl-1 pr-1">
                <label for="orderNumber"><upcase-string :string="key" /></label>
                <b-form-select
                  class="mb-2"
                  v-if="
                    editableFields.find((i) => i.key === key).type === 'select'
                  "
                  v-model="modalOrder[key]"
                  :options="editableFields.find((i) => i.key === key).options"
                ></b-form-select>
                <input
                  v-else
                  type="text"
                  id="key"
                  class="form-control"
                  v-model="modalOrder[key]"
                />
                <errors-list
                  v-if="errors[modalIndex][key]"
                  :list="errors[modalIndex][key]"
                />
              </div>
            </b-col>
          </div>
        </b-row>
      </b-container>
      <b-card class="mt-3">
        <p>Total quantity: {{ qt || " - " }}</p>
        <p>Total weight: {{ weight ? `${weight} Kg` : " - " }}</p>
        <p>
          Total value: {{ value || " - " }}
          {{ modalOrder.currency ? `(${modalOrder.currency})` : "" }}
        </p>
      </b-card>
      <div
        class="mt-4"
        v-for="(volume, index_volume) in modalOrder.volumes"
        :key="`volume${index_volume}`"
      >
        <b-card :title="`Box ${index_volume + 1}`" :sub-title="volume.name">
          <b-row cols="4">
            <template v-for="(vol_value, vol_key) in volume">
              <div
                v-if="
                  editableFieldsVolumes
                    .map((inner) => Object.values(inner)[0])
                    .includes(vol_key)
                "
                class="form-group mb-2"
                :key="vol_key"
              >
                <b-col class="text-center">
                  <label for="orderNumber"
                    ><upcase-string :string="vol_key"
                  /></label>
                  <input
                    type="text"
                    id="key"
                    class="form-control"
                    v-model="modalOrder.volumes[index_volume][vol_key]"
                  />
                  <errors-list
                    v-if="errors"
                    :list="
                      errors[modalIndex][`volumes[${index_volume}].${vol_key}`]
                    "
                  />
                </b-col>
              </div>
            </template>
          </b-row>
          <hr />
          <div
            v-for="(order_item, index_item) in volume.order_items"
            :key="index_item"
          >
            <div>
              <div class="accordion" id="accordionExample">
                <b-button
                  squared
                  block
                  variant="outline-primary mb-3"
                  size="lg"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  <div class="d-flex justify-content-between">
                    {{ order_item.name }} <i class="fas fa-caret-down"></i>
                  </div>
                </b-button>
                <div
                  id="collapseOne"
                  class="collapse"
                  aria-labelledby="headingOne"
                  data-parent="#accordionExample"
                >
                  <b-row class="mt-3 mb-3 pt-4" cols="2">
                    <template v-for="(item_value, item_key) in order_item">
                      <div
                        v-if="
                          editableFieldsOrderItems
                            .map((inner) => Object.values(inner)[0])
                            .includes(item_key)
                        "
                        class="form-group col mb-2"
                        :key="item_key"
                      >
                        <b-col>
                          <label for="orderNumber"
                            ><upcase-string :string="item_key"
                          /></label>
                          <b-form-select
                            class="mb-2"
                            v-if="
                              editableFieldsOrderItems.find(
                                (i) => i.key === item_key
                              ).type === 'select'
                            "
                            v-model="
                              modalOrder.volumes[index_volume].order_items[
                                index_item
                              ][item_key]
                            "
                            :options="
                              editableFieldsOrderItems.find(
                                (i) => i.key === item_key
                              ).options
                            "
                          ></b-form-select>
                          <input
                            v-else
                            type="text"
                            id="key"
                            class="form-control"
                            v-model="
                              modalOrder.volumes[index_volume].order_items[
                                index_item
                              ][item_key]
                            "
                          />
                          <errors-list
                            v-if="errors"
                            :list="
                              errors[modalIndex][
                                `volumes[${index_volume}].order_items[${index_item}].${item_key}`
                              ]
                            "
                          />
                        </b-col>
                      </div>
                    </template>
                  </b-row>
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </div>
    </b-modal>
  </div>
</template>

<script>
import OrderService from "../../services/OrderService";
import LoadingPage from "@/components/loadings/LoadingPage.vue";
import BackButton from "../../components/generic/BackButton";
import NbTable from "@/components/tables/NbTable.vue";
import ErrorsList from "@/components/errors/ErrorsList";
import UpcaseString from "@/components/texts/UpcaseString";
import CountryService from "../../services/CountryService";

const orderService = new OrderService();
const countryService = new CountryService();

export default {
  name: "AmazonImport",
  components: { LoadingPage, NbTable, BackButton, ErrorsList, UpcaseString },
  data: () => {
    return {
      fields: [
        { key: "status", label: "Status " },
        { key: "order_number", label: "Order Number" },
        { key: "contract_id", label: "Contract ID" },
        { key: "incoterm", label: "Incoterm" },
        { key: "customer_full_name", label: "Customer Full Name" },
        { key: "customer_document_number", label: "Customer Document Number" },
        { key: "customer_postal_code", label: "Customer Postal Code" },
        { key: "customer_address", label: "Customer Address" },
        { key: "customer_address_number", label: "Customer Address Number" },
        {
          key: "customer_address_complement",
          label: "Customer Address Complement",
        },
        {
          key: "customer_address_reference",
          label: "Customer Address Reference",
        },
        { key: "customer_city", label: "Customer City" },
        { key: "customer_state", label: "Customer State" },
        { key: "customer_country", label: "Customer Country" },
        { key: "customer_phone", label: "Customer Phone" },
        { key: "customer_email", label: "Customer Email" },
        { key: "freight_value", label: "Freight" },
        { key: "currency", label: "Currency" },
      ],
      editableFields: [
        { key: "order_number", label: "Status" },
        { key: "customer_full_name", label: "Status" },
        { key: "customer_document_type", label: "Status" },
        { key: "customer_document_number", label: "Status" },
        { key: "customer_phone_country_code", label: "Status" },
        { key: "customer_phone", label: "Status" },
        {
          key: "customer_country",
          label: "Status",
          type: "select",
          options: [],
        },
        { key: "customer_postal_code", label: "Status" },
        { key: "customer_address", label: "Status" },
        { key: "customer_address_number", label: "Status" },
        { key: "customer_address_complement", label: "Status" },
        { key: "customer_address_reference", label: "Status" },
        { key: "customer_state", label: "Status" },
        { key: "customer_city", label: "Status" },
        { key: "customer_email", label: "Status" },
        { key: "freight_value", label: "Status" },
        { key: "currency", label: "Status" },
        {
          key: "incoterm",
          label: "Status",
          type: "select",
          options: [
            { text: "Sender (DDP)", value: "DDP" },
            { text: "Receiver (DDU)", value: "DDU" },
          ],
        },
        { key: "contract_id", label: "Status", type: "select", options: [] },
      ],
      editableFieldsVolumes: [
        { key: "weight", label: "Status" },
        { key: "height", label: "Status" },
        { key: "width", label: "Status" },
        { key: "length", label: "Status" },
      ],
      editableFieldsOrderItems: [
        { key: "weight", label: "Status" },
        { key: "height", label: "Status" },
        { key: "width", label: "Status" },
        { key: "length", label: "Status" },
        { key: "name", label: "Status" },
        { key: "sku", label: "Status" },
        { key: "value", label: "Status" },
        { key: "quantity", label: "Status" },
        { key: "hs_code", label: "Status" },
        { key: "height", label: "Status" },
        { key: "width", label: "Status" },
        { key: "length", label: "Status" },
        { key: "weight", label: "Status" },
        { key: "tax_value", label: "Status" },
        { key: "origin_country", label: "Status", type: "select", options: [] },
        { key: "description", label: "Status" },
      ],
      loaded: true,
      orders: [],
      errors: [],
      amazon_file_id: null,
      contracts: [],
      modalOrder: {},
      countries: {},
      load: false,
      modalIndex: null,
      dummy_var: 1,
      unique: 1,
    };
  },
  created() {
    this.getCountries();
    this.orders = this.$router.history.current.query.orders;
    this.errors = this.$router.history.current.query.errors;
    this.contracts = this.$router.history.current.query.contracts;
    this.contracts.unshift({ text: "Please choose a contract", value: null });
    this.amazon_file_id = this.$router.history.current.query.amazon_file_id;
    this.loaded = false;
    this.load = true;
    this.addOptionsToEditableFields("contract_id", this.contracts);
  },
  methods: {
    addOptionsToEditableFields(key, options) {
      this.editableFields.find((x) => x.key === key).options = options;
    },
    addOptionsToEditableFieldsOrderItems(key, options) {
      this.editableFieldsOrderItems.find((x) => x.key === key).options =
        options;
    },
    clicked(index) {
      this.modalIndex = index;
      this.modalOrder = this.orders[index];
      this.$bvModal.show("modalAmazonImport");
    },
    checkOrder(bvModalEvt) {
      bvModalEvt.preventDefault();
      let temp = {};
      Object.assign(temp, this.modalOrder);
      temp.volumes_attributes = this.modalOrder.volumes;
      for (let i = 0; i < temp.volumes_attributes.length; i++) {
        temp.volumes_attributes[i].order_items_attributes =
          temp.volumes_attributes[i].order_items;
      }
      orderService.checkOrder(temp).then((response) => {
        this.errors[this.modalIndex] = response.data.data.errors;
        this.dummy_var += 1;
        this.unique += 1;
        this.$forceUpdate();
        if (response.data.data.valid) {
          this.orders[this.modalIndex] = this.modalOrder;
          this.$bvModal.hide("modalAmazonImport");
          this.$helpers.toast(response.data.messages[0], "success");
        } else {
          this.$helpers.toast(response.data.messages[0], "danger");
        }
      });
    },
    saveOrders() {
      let error_exist = false;
      for (let i in this.errors) {
        if (Object.keys(this.errors[i]).length > 0) {
          error_exist = true;
        }
      }
      if (this.errors.length >= 1 && error_exist) {
        this.$helpers.toast("There are still fields to fix", "danger");
        return;
      }
      this.orders.forEach((order) => {
        order.volumes_attributes = order.volumes;
        delete order.volumes;
        delete order.index;
        delete order.seller;
        order.volumes_attributes.forEach((volume) => {
          volume.order_items_attributes = volume.order_items;
          delete volume.order_items;
        });
      });
      orderService
        .saveOrders(this.orders, this.amazon_file_id)
        .then((response) => {
          if (response.data.data.saved) {
            this.$router.push("/orders");
            this.$helpers.toast(response.data.messages[0], "success");
          } else {
            this.$helpers.toast(response.data.messages[0], "danger");
            for (let i in response.data.data.errors) {
              let e = response.data.data.errors[i];
              this.errors = e.errors || this.setNoError();
            }
          }
        });
    },
    getCountries() {
      countryService.getCountries().then((response) => {
        let countries = response.data.data;
        let countries_options = [];
        for (let country of countries) {
          countries_options.push({
            text: country.name,
            value: country.alpha2_code,
          });
        }
        this.addOptionsToEditableFields("customer_country", countries_options);
        this.addOptionsToEditableFieldsOrderItems(
          "origin_country",
          countries_options
        );
      });
    },
    setNoError() {
      return {
        order_number: null,
        customer_full_name: null,
        customer_document_type: null,
        customer_document_number: null,
      };
    },
  },
  computed: {
    weight() {
      let weight = 0;
      for (let v in this.modalOrder.volumes) {
        weight = weight + (parseFloat(this.modalOrder.volumes[v].weight) || 0);
        for (var i in this.modalOrder.volumes[v].order_items) {
          let item = this.modalOrder.volumes[v].order_items[i];
          weight =
            weight +
            (parseFloat(item.quantity) || 0) * (parseFloat(item.weight) || 0);
        }
      }
      return weight;
    },
    qt() {
      let qt = 0;
      for (let v in this.modalOrder.volumes) {
        for (var i in this.modalOrder.volumes[v].order_items) {
          let item = this.modalOrder.volumes[v].order_items[i];
          qt = qt + (parseFloat(item.quantity) || 0);
        }
      }
      return qt;
    },
    value() {
      let value = 0;
      for (let v in this.modalOrder.volumes) {
        for (var i in this.modalOrder.volumes[v].order_items) {
          let item = this.modalOrder.volumes[v].order_items[i];
          value =
            value +
            (parseFloat(item.quantity) || 0) * (parseFloat(item.value) || 0);
        }
      }
      return value;
    },
  },
};
</script>
