var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loaded)?_c('LoadingPage'):_c('div',[_c('div',[_c('back-button'),_c('div',{staticClass:"d-flex justify-content-between mt-3"},[_c('h5',{staticClass:"font-weight-light text-uppercase"},[_vm._v("Orders to Fix")]),_c('button',{staticClass:"btn btn-primary",attrs:{"title":"Unselect All Columns"},on:{"click":function($event){return _vm.saveOrders()}}},[_vm._v(" Save Orders ")])]),_c('NbTable',{key:_vm.unique,staticClass:"pb-1",attrs:{"shortThead":true,"tableOf":"Amazon Import","total":_vm.orders.length,"items":_vm.orders,"fields":_vm.fields,"clickable":true},on:{"clicked":_vm.clicked},scopedSlots:_vm._u([{key:"cell(status)",fn:function(data){return [_c('div',[(Object.keys(_vm.errors[data.item.index]).length === 0)?_c('span',{staticClass:"badge badge-success"},[_vm._v("Ok to save")]):_c('span',{staticClass:"badge badge-danger"},[_vm._v("Incompleted ")])])]}},{key:"cell(order_number)",fn:function(data){return [(
            _vm.errors[data.item.index] && _vm.errors[data.item.index].order_number
          )?_c('div',{staticClass:"text-danger"},[_c('i',{staticClass:"fas fa-exclamation-triangle"})]):_vm._e()]}},{key:"cell(contract_id)",fn:function(data){return [(
            _vm.errors[data.item.index] && _vm.errors[data.item.index].contract_id
          )?_c('div',{staticClass:"text-danger"},[_c('i',{staticClass:"fas fa-exclamation-triangle"})]):_vm._e()]}},{key:"cell(incoterm)",fn:function(data){return [(_vm.errors[data.item.index] && _vm.errors[data.item.index].incoterm)?_c('div',{staticClass:"text-danger"},[_c('i',{staticClass:"fas fa-exclamation-triangle"})]):_vm._e()]}},{key:"cell(customer_full_name)",fn:function(data){return [(
            _vm.errors[data.item.index] &&
            _vm.errors[data.item.index].customer_full_name
          )?_c('div',{staticClass:"text-danger"},[_c('i',{staticClass:"fas fa-exclamation-triangle"})]):_vm._e()]}},{key:"cell(customer_document_number)",fn:function(data){return [(
            _vm.errors[data.item.index] &&
            _vm.errors[data.item.index].customer_document_number
          )?_c('div',{staticClass:"text-danger"},[_c('i',{staticClass:"fas fa-exclamation-triangle"})]):_vm._e()]}},{key:"cell(customer_postal_code)",fn:function(data){return [(
            _vm.errors[data.item.index] &&
            _vm.errors[data.item.index].customer_postal_code
          )?_c('div',{staticClass:"text-danger"},[_c('i',{staticClass:"fas fa-exclamation-triangle"})]):_vm._e()]}},{key:"cell(customer_address)",fn:function(data){return [(
            _vm.errors[data.item.index] &&
            _vm.errors[data.item.index].customer_address
          )?_c('div',{staticClass:"text-danger"},[_c('i',{staticClass:"fas fa-exclamation-triangle"})]):_vm._e()]}},{key:"cell(customer_address_number)",fn:function(data){return [(
            _vm.errors[data.item.index] &&
            _vm.errors[data.item.index].customer_address_number
          )?_c('div',{staticClass:"text-danger"},[_c('i',{staticClass:"fas fa-exclamation-triangle"})]):_vm._e()]}},{key:"cell(customer_address_complement)",fn:function(data){return [(
            _vm.errors[data.item.index] &&
            _vm.errors[data.item.index].customer_address_complement
          )?_c('div',{staticClass:"text-danger"},[_c('i',{staticClass:"fas fa-exclamation-triangle"})]):_vm._e()]}},{key:"cell(customer_address_reference)",fn:function(data){return [(
            _vm.errors[data.item.index] &&
            _vm.errors[data.item.index].customer_address_reference
          )?_c('div',{staticClass:"text-danger"},[_c('i',{staticClass:"fas fa-exclamation-triangle"})]):_vm._e()]}},{key:"cell(customer_city)",fn:function(data){return [(
            _vm.errors[data.item.index] && _vm.errors[data.item.index].customer_city
          )?_c('div',{staticClass:"text-danger"},[_c('i',{staticClass:"fas fa-exclamation-triangle"})]):_vm._e()]}},{key:"cell(customer_state)",fn:function(data){return [(
            _vm.errors[data.item.index] && _vm.errors[data.item.index].customer_state
          )?_c('div',{staticClass:"text-danger"},[_c('i',{staticClass:"fas fa-exclamation-triangle"})]):_vm._e()]}},{key:"cell(customer_country)",fn:function(data){return [(
            _vm.errors[data.item.index] &&
            _vm.errors[data.item.index].customer_country
          )?_c('div',{staticClass:"text-danger"},[_c('i',{staticClass:"fas fa-exclamation-triangle"})]):_vm._e()]}},{key:"cell(customer_phone)",fn:function(data){return [(
            _vm.errors[data.item.index] && _vm.errors[data.item.index].customer_phone
          )?_c('div',{staticClass:"text-danger"},[_c('i',{staticClass:"fas fa-exclamation-triangle"})]):_vm._e()]}},{key:"cell(customer_email)",fn:function(data){return [(
            _vm.errors[data.item.index] && _vm.errors[data.item.index].customer_email
          )?_c('div',{staticClass:"text-danger"},[_c('i',{staticClass:"fas fa-exclamation-triangle"})]):_vm._e()]}},{key:"cell(freight_value)",fn:function(data){return [(
            _vm.errors[data.item.index] && _vm.errors[data.item.index].freight_value
          )?_c('div',{staticClass:"text-danger"},[_c('i',{staticClass:"fas fa-exclamation-triangle"})]):_vm._e()]}},{key:"cell(currency)",fn:function(data){return [(_vm.errors[data.item.index] && _vm.errors[data.item.index].currency)?_c('div',{staticClass:"text-danger"},[_c('i',{staticClass:"fas fa-exclamation-triangle"})]):_vm._e()]}}])})],1),_c('b-modal',{attrs:{"id":"modalAmazonImport","size":"lg","title":"Edit Order Details","ok-title":"Check Orders","scrollable":""},on:{"ok":_vm.checkOrder}},[_c('b-container',[_c('b-row',{attrs:{"cols":"2"}},_vm._l((_vm.modalOrder),function(value,key){return _c('div',{key:key,staticClass:"bg-info"},[(
              _vm.editableFields
                .map((inner) => Object.values(inner)[0])
                .includes(key) && key !== 'volumes'
            )?_c('b-col',{key:key},[_c('div',{staticClass:"form-group mb-2 pl-1 pr-1"},[_c('label',{attrs:{"for":"orderNumber"}},[_c('upcase-string',{attrs:{"string":key}})],1),(
                  _vm.editableFields.find((i) => i.key === key).type === 'select'
                )?_c('b-form-select',{staticClass:"mb-2",attrs:{"options":_vm.editableFields.find((i) => i.key === key).options},model:{value:(_vm.modalOrder[key]),callback:function ($$v) {_vm.$set(_vm.modalOrder, key, $$v)},expression:"modalOrder[key]"}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.modalOrder[key]),expression:"modalOrder[key]"}],staticClass:"form-control",attrs:{"type":"text","id":"key"},domProps:{"value":(_vm.modalOrder[key])},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.modalOrder, key, $event.target.value)}}}),(_vm.errors[_vm.modalIndex][key])?_c('errors-list',{attrs:{"list":_vm.errors[_vm.modalIndex][key]}}):_vm._e()],1)]):_vm._e()],1)}),0)],1),_c('b-card',{staticClass:"mt-3"},[_c('p',[_vm._v("Total quantity: "+_vm._s(_vm.qt || " - "))]),_c('p',[_vm._v("Total weight: "+_vm._s(_vm.weight ? `${_vm.weight} Kg` : " - "))]),_c('p',[_vm._v(" Total value: "+_vm._s(_vm.value || " - ")+" "+_vm._s(_vm.modalOrder.currency ? `(${_vm.modalOrder.currency})` : "")+" ")])]),_vm._l((_vm.modalOrder.volumes),function(volume,index_volume){return _c('div',{key:`volume${index_volume}`,staticClass:"mt-4"},[_c('b-card',{attrs:{"title":`Box ${index_volume + 1}`,"sub-title":volume.name}},[_c('b-row',{attrs:{"cols":"4"}},[_vm._l((volume),function(vol_value,vol_key){return [(
                _vm.editableFieldsVolumes
                  .map((inner) => Object.values(inner)[0])
                  .includes(vol_key)
              )?_c('div',{key:vol_key,staticClass:"form-group mb-2"},[_c('b-col',{staticClass:"text-center"},[_c('label',{attrs:{"for":"orderNumber"}},[_c('upcase-string',{attrs:{"string":vol_key}})],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.modalOrder.volumes[index_volume][vol_key]),expression:"modalOrder.volumes[index_volume][vol_key]"}],staticClass:"form-control",attrs:{"type":"text","id":"key"},domProps:{"value":(_vm.modalOrder.volumes[index_volume][vol_key])},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.modalOrder.volumes[index_volume], vol_key, $event.target.value)}}}),(_vm.errors)?_c('errors-list',{attrs:{"list":_vm.errors[_vm.modalIndex][`volumes[${index_volume}].${vol_key}`]}}):_vm._e()],1)],1):_vm._e()]})],2),_c('hr'),_vm._l((volume.order_items),function(order_item,index_item){return _c('div',{key:index_item},[_c('div',[_c('div',{staticClass:"accordion",attrs:{"id":"accordionExample"}},[_c('b-button',{attrs:{"squared":"","block":"","variant":"outline-primary mb-3","size":"lg","data-toggle":"collapse","data-target":"#collapseOne","aria-expanded":"true","aria-controls":"collapseOne"}},[_c('div',{staticClass:"d-flex justify-content-between"},[_vm._v(" "+_vm._s(order_item.name)+" "),_c('i',{staticClass:"fas fa-caret-down"})])]),_c('div',{staticClass:"collapse",attrs:{"id":"collapseOne","aria-labelledby":"headingOne","data-parent":"#accordionExample"}},[_c('b-row',{staticClass:"mt-3 mb-3 pt-4",attrs:{"cols":"2"}},[_vm._l((order_item),function(item_value,item_key){return [(
                        _vm.editableFieldsOrderItems
                          .map((inner) => Object.values(inner)[0])
                          .includes(item_key)
                      )?_c('div',{key:item_key,staticClass:"form-group col mb-2"},[_c('b-col',[_c('label',{attrs:{"for":"orderNumber"}},[_c('upcase-string',{attrs:{"string":item_key}})],1),(
                            _vm.editableFieldsOrderItems.find(
                              (i) => i.key === item_key
                            ).type === 'select'
                          )?_c('b-form-select',{staticClass:"mb-2",attrs:{"options":_vm.editableFieldsOrderItems.find(
                              (i) => i.key === item_key
                            ).options},model:{value:(
                            _vm.modalOrder.volumes[index_volume].order_items[
                              index_item
                            ][item_key]
                          ),callback:function ($$v) {_vm.$set(_vm.modalOrder.volumes[index_volume].order_items[
                              index_item
                            ], item_key, $$v)},expression:"\n                            modalOrder.volumes[index_volume].order_items[\n                              index_item\n                            ][item_key]\n                          "}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                            _vm.modalOrder.volumes[index_volume].order_items[
                              index_item
                            ][item_key]
                          ),expression:"\n                            modalOrder.volumes[index_volume].order_items[\n                              index_item\n                            ][item_key]\n                          "}],staticClass:"form-control",attrs:{"type":"text","id":"key"},domProps:{"value":(
                            _vm.modalOrder.volumes[index_volume].order_items[
                              index_item
                            ][item_key]
                          )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.modalOrder.volumes[index_volume].order_items[
                              index_item
                            ], item_key, $event.target.value)}}}),(_vm.errors)?_c('errors-list',{attrs:{"list":_vm.errors[_vm.modalIndex][
                              `volumes[${index_volume}].order_items[${index_item}].${item_key}`
                            ]}}):_vm._e()],1)],1):_vm._e()]})],2)],1)],1)])])})],2)],1)})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }