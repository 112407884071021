<template>
  <div class="d-inline-block">
    <button
      type="button"
      class="dropdown-toggle button-primary"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      {{ $t("components.maxRows.button") }} {{ val }}
    </button>
    <div class="dropdown-menu">
      <!--
      <input
        type="number"
        class="form-control ml-2"
        style="max-width: 140px;"
        placeholder="1~900"
        v-model="val"
      />
      <button class="btn btn-success m-left" @click="saveItemsPerPage">Save</button>
      -->

      <a
        class="dropdown-item text-center"
        href="#"
        v-on:click="
          val = 10;
          saveItemsPerPage();
        "
        >10</a
      >
      <a
        class="dropdown-item text-center"
        href="#"
        v-on:click="
          val = 20;
          saveItemsPerPage();
        "
        >20</a
      >
      <a
        class="dropdown-item text-center"
        href="#"
        v-on:click="
          val = 50;
          saveItemsPerPage();
        "
        >50</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "ItemsPerPage",

  props: {
    perPage: {
      required: true,
    },
    saveTo: {
      required: true,
    },
  },
  data: () => {
    return {
      val: "",
      itemsPerPage: {},
    };
  },
  beforeMount() {
    let storage = {};
    if (localStorage.getItem("itemsPerPage")) {
      storage = JSON.parse(localStorage.getItem("itemsPerPage"));
    }
    if (storage[this.saveTo]) {
      this.itemsPerPage[this.saveTo] = storage[this.saveTo];
    } else {
      this.itemsPerPage[this.saveTo] = 20;
    }
    this.val = this.itemsPerPage[this.saveTo];
    this.emitVal();
  },
  methods: {
    saveItemsPerPage() {
      let storage = {};
      if (localStorage.getItem("itemsPerPage")) {
        storage = JSON.parse(localStorage.getItem("itemsPerPage"));
      }
      storage[this.saveTo] = this.val;
      this.itemsPerPage[this.saveTo] = this.val;
      localStorage.setItem("itemsPerPage", JSON.stringify(storage));
      this.emitVal();
    },
    emitVal() {
      if (this.val <= 0) {
        this.val = 20;
      }
      this.$emit("emitValue", this.val);
    },
  },
  watch: {
    /*
      val (value) {
        if(value <= 0) {
          value = 20
          this.val = value
        }
        this.$emit('emitValue', value)
      },
      */
  },
};
</script>

<style scoped>
.m-left {
  margin-left: 32px !important;
  margin-top: 8px !important;
}
</style>
